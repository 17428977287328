.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  min-height: 50vh;
}

// .opposites {
//     position: relative;
//     width: 60px;
//     height: 60px;
//   }
//   .opposites {
//     animation: opposites 2.5s ease-out 0s infinite;  
//   }
//   .tl, .tr, .br, .bl {
//     width: 30px;
//     height: 30px;
//     position: absolute;
//   }
//   .tl, .tr {
//     top: 0; 
//   }
//   .tr, .br {
//     left: 30px; 
//   }
//   .tl, .br {
//     animation: tlbr 2.5s ease-out 0s infinite;
//   }
//   .br, .bl {
//     top: 30px; 
//   }
//   .tl, .bl {
//     left: 0; 
//   }
//   .tr, .bl {
//     animation: trbl 2.5s ease-out 0s infinite;
//   }
//   .tl {
//     background: red;
//     transform-origin: bottom right;
//   }
//   .tr {
//     background: green; 
//     transform-origin: bottom left;
//   }
//   .br {
//     background: dodgerblue; 
//     transform-origin: top left;
//   }
//   .bl {
//     background: gold; 
//     transform-origin: top right;
//   }
  
//   @keyframes tlbr {
//     0% {transform: rotate(0);}
//     20% {transform: rotate(90deg);}
//     40% {transform: rotate(90deg);}
//     60% {transform: rotate(0);}
//   }
//   @keyframes trbl {
//     20% {transform: rotate(0);}
//     40% {transform: rotate(90deg);}
//     60% {transform: rotate(90deg);}
//     80% {transform: rotate(0);}
//   }
//   @keyframes opposites {
//     80% {transform: rotate(0deg);}
//     100% {transform: rotate(360deg);}
//   }




$grey: rgba(0, 0, 0, .5);
$blue: rgba(0, 0, 255, .5);

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@mixin loaderDivMixin {
	border-radius: 50%;
	padding: 8px;
	border: 2px solid transparent;
	animation: rotate linear 3.5s infinite;
}

.loader {
	position: relative;
	margin: 75px auto;
	width: 150px;
	height: 150px;
	display: block;
	overflow: hidden;
	div {
		height: 100%;
	}
}

/* loader 1 */
.loader1, .loader1 div {
	@include loaderDivMixin;
	border-top-color: $grey;
	border-bottom-color: $blue;
}













  

.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}