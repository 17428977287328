
.todo-block {
	font-size: 16px;
	//min-width: 1000px;

	.form-check-input {
        width: 40px;
        height: 40px;
    }

	.content {
		.task {
			& > .row {
				&:first-child {
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
				}
				&:last-child {
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
				}
				&:nth-child(odd) {
					background: rgb(248, 249, 250);
				}
	
				& > .col {
					display: flex;
					flex-direction: column;

					&:focus-visible {
						outline: 2px solid lightblue;
						border-radius: 3px;
					}

					& .text-column {
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;

						@include md-d {
							flex: 0 0 auto;
							min-height: 90px;
						}
					}

					.fields {
						.col {
							padding: 1rem;
							display: flex;
							align-items: center;
							position: relative;

							&:after {
								content: attr(data-label);
								bottom: 2px;
								color: #d3d3d3;
								font-size: .8rem;
								left: 50%;
								position: absolute;
								-webkit-transform: translateX(-50%);
								transform: translateX(-50%);
								pointer-events: none;
							}

							&.text-column {
								align-items: flex-start;
							}
						}
					}


					//span {
					//	background-color: inherit !important;
					//}

					.react-datepicker-wrapper {
						width: auto;
					}
				}
			}
		}
	}
}

.manipulate-btn {
	font-size: 30px;
	cursor: pointer;
	padding: 0;

	svg {
		transition: .2s;
		
		&:hover {
			fill: #D8840A;
		}
	}
}

.add-subtask-btn {
	width: 30px;
	height: 30px;
	cursor: pointer;

	svg {
		width: 100%;
		height: 100%;
		fill: #4a4a4a;
	}

	&:hover {
		svg {
			fill: #000000;
		}
	}
}

.delete-task-button {
	cursor: pointer;

	&[disabled] {
		pointer-events: none;
	}

	svg {
		font-size: 30px;
		fill: #dc3545;
	}
}
