.form-control:disabled,
.form-select:disabled {
    background-color: #fff;
}
.form-switch {
    .form-check-input:checked {
        background-color: #198754;
        border-color: #198754;
        border: none;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
    }

    .form-check-input:not(:checked) {
        background-color: #e6431a;
        border-color: #e6431a;
        border: none;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
    }

    .form-check-input.active-disabled { 
        background-color: rgba(0,0,0,.25);
        border-color: rgba(0,0,0,.25);
        border: none;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
    }
}