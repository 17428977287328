.gantt-chart {
	.chart {
		overflow-y: auto;
	}
	.chart-configuration {
		.form-select,
		.form-control {
			font-size: 12px;
			max-width: 100px;
		}

		.filter-button {
			cursor: pointer;
		}

		.datepicker {
			input {
				width: 100px;
			}
			span {
				font-size: 9px;
				opacity: .5;
			}
		}
	}
}
