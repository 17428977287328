.accordion {
    margin: 0 auto;
    border: 1px solid #f8f8f8;
    text-align: left;

    .accordion__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
         
        position: relative; 
        cursor: pointer;
        padding: 10px 15px; 
        margin: 0;
        background: #f8f8f8;
        color: black;

        p {
            margin-bottom: 0;
        }

        svg {
            transition: .1s;
            margin-left: 5px;
            font-size: 15px;
        }
    }
    
    .accordion__heading:before~.accordion__checkbox:checked {
        transform: rotate(90deg);
        opacity: 0;
        transition: all 0.1s;
    }
      
    .accordion__heading:after {
        transform: rotate(90deg);
        transition: all 0.1s;
    }
      
    .accordion__checkbox:checked~.accordion__heading {
        svg {
            transform: rotate(-180deg);            
        }
    }
      
    .accordion__checkbox {
        display: none; 
        position: absolute; 
        right: -9999em;
    }
      
    .accordion__checkbox:checked~.accordion__content {
        max-height: 1000px;
        transition: all 2s;
    }
      
    .accordion__content {
        max-height: 0; 
        overflow: hidden; 
        padding: 0 15px;
        transition: all 0.5s;

        .files {
            margin: 1rem 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            ul {
                list-style: none;
                padding: 0;
                max-height: 800px;
                overflow: auto;
                margin-bottom: 0px;
    
                li {
                    padding: 0.5rem 0;
                    position: relative;
                    cursor: pointer;
                    color: black;

                    a {
                        text-decoration: none;
                        color: inherit;

                        &:hover {
                            color: #e6431a;
                        }
                    }

                    .line-through {
                        text-decoration: line-through;
                        color: #6c6d6e;

                        &:hover {
                            color: #e6431a;
                        }
                    }

                    svg {
                        fill: black;

                        &:hover {
                            fill: #e6431a;
                        }
                    }
                }
            }
        }
        &.container {
            width: 100%;
            padding: 0 2rem;
        }
    }

    .file {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-items: stretch;
        padding: 1.5rem 0;
    
        .number {
            width: 30px;
            flex-shrink: 0;
        }
        .name {
            flex-grow: 1;
            padding: 0 1rem;
        }
        .icon {
            flex-shrink: 0;
            width: 30px;
            height: 30px;
        }
    }
    .append_file {
        width: 100%;
        padding: 10px 0;
        
        .add_file {
            width: 20px;
            height: 20px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            transition: .2s;

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 80%;
                height: 3px;
                background: black;
            }

            &:before {
                transform: rotate(0);
            }

            &:after {
                transform: rotate(90deg);
            }
        }
    }    
}






// Loader - при скачивании файла [ бегущие часы ]
[class="loader-20"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
}
.loader-20 {
    background-color: transparent;
    box-shadow: inset 0px 0px 0px .1em currentcolor;
    border-radius: 50%;
    position: relative;

    &:after,
    &:before {
        position: absolute;
        content: "";
        background-color: currentcolor;
        top: .5em;
        left: .5em;
        height: .1em;
        transform-origin: left center;
    }
    &:after {
        width: .4em;
        animation: loader-20 2s linear infinite;
    }
    &:before {
        width: .3em;
        animation: loader-20 8s linear infinite;
    }
}

@keyframes loader-20 {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}