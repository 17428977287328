.file-upload-modal {
    #form-file-upload {
        height: 16rem;
        width: 28rem;
        max-width: 100%;
        text-align: center;
        position: relative;
    }
      
    #input-file-upload {
        display: none;
    }
      
    #label-file-upload {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 1rem;
        border-style: dashed;
        border-color: #cbd5e1;
        background-color: #f8fafc;
    }
      
    #label-file-upload.drag-active {
        background-color: #ffffff;
    }
      
    .upload-button {
        cursor: pointer;
        padding: 0.25rem;
        font-size: 1rem;
        border: none;
        font-family: 'Oswald', sans-serif;
        background-color: transparent;
    }
      
    .upload-button:hover {
        text-decoration-line: underline;
    }
      
    #drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }


    .modal-body {
        .files {
            margin: 1rem 0;
            
            ul {
                list-style: none;
                padding: 0;
                max-height: 800px;
                overflow: auto;
                margin-bottom: 1rem;
        
                li {
                    padding: 0.5rem 0;
                    padding-right: 2rem;
                    position: relative;
        
                    & > div {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
        
                        &:hover {
                            .file-name {
                                color: #e6431a;
                            }
                        }
        
                        span.file-size {
                            color: #999;
                            padding: 0 0.5rem;
                        }
                    }
        
                    i{
                        cursor: pointer;
                        position:absolute;
                        top:50%;
                        right:0;
                        transform:translatey(-50%);
                    }
                }
            }
        }
    }
}

// Loader - при добавлении файла
.fond{
    background-color: rgba(255,255,255,.75);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    
    .contener_general {
        -webkit-animation:animball_two 1s infinite;
        -moz-animation:animball_two 1s infinite;
        -ms-animation:animball_two 1s infinite;
        animation:animball_two 1s infinite;
        width:44px; height:44px;
        
        .contener_mixte {
            width:44px; height:44px; position:absolute;
            
            .ballcolor {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            .ball_1, .ball_2, .ball_3, .ball_4 {
              position: absolute;
              -webkit-animation:animball_one 1s infinite ease;
              -moz-animation:animball_one 1s infinite ease;
              -ms-animation:animball_one 1s infinite ease;
              animation:animball_one 1s infinite ease;
            }
            .ball_1 {
              background-color:#cb2025;
              top:0; left:0;
            }
            .ball_2 {
              background-color:#f8b334;
              top:0; left:24px;
            }
            .ball_3 {
              background-color:#00a096;
              top:24px; left:0;
            }
            .ball_4 {
              background-color:#97bf0d;
              top:24px; left:24px;
            }
        }
    }
    
    @-webkit-keyframes animball_one {
      0%{ position: absolute;}
      50%{top:12px; left:12px; position: absolute;opacity:0.5;}
      100%{ position: absolute;}
    }
    @-moz-keyframes animball_one {
      0%{ position: absolute;}
      50%{top:12px; left:12px; position: absolute;opacity:0.5;}
      100%{ position: absolute;}
    }
    @-ms-keyframes animball_one {
      0%{ position: absolute;}
      50%{top:12px; left:12px; position: absolute;opacity:0.5;}
      100%{ position: absolute;}
    }
    @keyframes animball_one {
      0%{ position: absolute;}
      50%{top:12px; left:12px; position: absolute;opacity:0.5;}
      100%{ position: absolute;}
    }
    
    @-webkit-keyframes animball_two {
      0%{-webkit-transform:rotate(0deg) scale(1);}
      50%{-webkit-transform:rotate(360deg) scale(1.3);}
      100%{-webkit-transform:rotate(720deg) scale(1);}
    }
    @-moz-keyframes animball_two {
      0%{-moz-transform:rotate(0deg) scale(1);}
      50%{-moz-transform:rotate(360deg) scale(1.3);}
      100%{-moz-transform:rotate(720deg) scale(1);}
    }
    @-ms-keyframes animball_two {
      0%{-ms-transform:rotate(0deg) scale(1);}
      50%{-ms-transform:rotate(360deg) scale(1.3);}
      100%{-ms-transform:rotate(720deg) scale(1);}
    }
    @keyframes animball_two {
      0%{transform:rotate(0deg) scale(1);}
      50%{transform:rotate(360deg) scale(1.3);}
      100%{transform:rotate(720deg) scale(1);}
    }
}