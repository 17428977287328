.navbar {
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 0%);

    .container-fluid {
        display: flex;
        justify-content: space-between;
    }

    .menu-dropdown {
        user-select: none;
        cursor: pointer;        
    }
    .dropdown-menu {
        @include sm-d {
            display: block;
        }
    }
}
.logo {
	max-width: 160px;
	max-height: 90px;
}

.link {
	// color: black;
	text-decoration: unset;
    display: flex;
    align-items: center;

	&:hover {
		text-decoration: unset;
	}
}

.navbar-collapse {
    flex-grow: 0;
}