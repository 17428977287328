@import "media.scss";
@import "./config/vars";
@import "./config/breakpoints";
@import "./pages/homepage.scss";
@import "./pages/project-detail.scss";
@import "./modals/filter-modal.scss";
@import "./modals/add-file-modal.scss";
@import "./parts/inputs.scss";
@import "./parts/buttons/filter-button.scss";
@import "./parts/loaders/running-clocks.scss";
@import "./components/files-accordion.scss";
@import "./components/subtasks.scss";
@import "./parts/search-box.scss";
@import "colors";

// pages
@import "./general/Header.scss";

.main_wrapper {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
}

.cnt_wrapper {
    padding: 1.5rem 0 4rem;
}

.show {
    display: block !important;
}

.navbar-nav {
    max-height: auto !important;
}
// .dropdown {
//     .dropdown-menu {
//         @include lg {
//             display: block;
//         }
//     }

//     .dropdown-item {
//         @include lg {
//             text-align: center;
//         }
//     }
// }

.add_new_project {
    cursor: pointer;
    
    svg {
        width: 30px;
        height: 30px;
        fill: #495057;
        transition: 300ms;
    }

    &:hover {
        svg {
            fill: #80bdff;
        }
    }
}


.group_block {
    .title_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        @include sm {
            flex-direction: column;
        }

        .search_wrapper {
            display: flex;
            align-items: center;

            @include sm {
                width: 100%;
            }

            .search_box {
                width: auto;
                display: flex;
                align-items: center;
                position: relative;
        
                @include sm {
                    width: 100%;
                }
                
                .dropdown-menu {
                    min-width: 100%;
                    left: auto;
                    right: 0;
                    top: 102%;
                    max-height: 300px;
                    overflow-y: auto;

                    @include sm {
                        width: 100%;
                    }
                }
                
    
                .input-group-prepend {
                    align-self: stretch;
                }
                
                img {
                    max-width: 20px;
                    max-height: 20px;
                }
            }
        }
    }

    .controls_block {
        display: flex;
        justify-content: end;
        justify-content: space-between;

        @include sm {
            flex-direction: column;
        }

        .control {
            display: flex;
            align-items: center;

            @include sm {
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .title {
                color: gray;
                margin-right: 0.3rem;
                text-align: left;

                @include sm {
                    font-size: 0.9rem;
                    min-width: 79px;
                }
            }
        }

        select {
            width: auto;

            @include sm {
                width: 100%;
            }
        }
    }
    
    .customers-table {
        border-radius: .4em;
    
        .row {
            margin-right: 0;
            margin-left: 0;
            border-bottom: 1px solid #ddd;
            text-decoration: none;
            transition: .1s;
    
            &:last-child {
                border-bottom: 0;
            }
    
            &:hover {
                background: #e9ecef;
            }
    
            &.row-header {
                border-top: none;
                font-weight: bold;
                border: none;
                background:  #e9ecef;
    
                @include md {
                    display: none;
                }
            }
    
            .col {
                padding: 10px;
                text-align: left;
                border-top: none;
                color: #000;
                overflow: hidden;
    
                @include md {
                    &:not(:last-child):before {
                        content: attr(data-label);
                        margin-right: 5px;
                        font-weight: bold;
                    }
                }
    
                span {
                    color: white;
                    border-radius: .2rem;
                    text-align: center;
                    
                    &:not(.inline) {
                        display: block;                        
                    }
                }

                &.status {
                    font-size: 0.8rem;
                }
            }
        }
    }
}


.detail-page {
    font-size: 1.2rem;
    padding-bottom: 3rem;

    .control-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .5rem;

        @include lg {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 1rem;
        }

        .title {
            text-align: left;

            @include lg {
                margin-bottom: 1rem;
            }

            @include sm {
                font-size: 1.3rem;
            }
        }

        .controls {
            display: flex;

            .btn {
                margin-left: 0.5rem;

                @include lg {
                    margin-left: 0;
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .row {
        margin-bottom: .5rem;
        
        .label {
            color: #6c6d6e;
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: .5rem;

            @include sm {
                font-size: 1rem;
            }
        }
        .value {
            color: #737B92;
            line-height: 1.7;
            font-size: 1.1rem;
            margin-bottom: 20px;

            @include sm {
                font-size: 1rem;
            }
        }
    }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #9b969c #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #9b969c;
    border-radius: 10px;
    border: 3px solid #ffffff;
}

























