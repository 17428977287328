
.text-lightbrown {
  color: #DF6D2D;
}

.text-lightyellow {
  color: #FFF0BD;
}

.text-brightred {
  color: #FB4141;
}

.text-violet {
  color: #6f42C1;
}