.search-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .dropdown-menu {
        min-width: 280px;
        max-width: 300px;
        position: absolute;
        top: 101%;
        right: 0;
        overflow: hidden;

        @include sm-d {
            overflow-y: auto;
            max-height: 50vh;
        }

        a {
            white-space: wrap;
        }
    }
}