.main-page {
    width: 100%;
    overflow-x: hidden;
    padding: 2rem 0 7rem;
}

#mainpage {
	#additional {
		.btn {
			@include sm-d {
				font-size: 13px;
			}
		}
	}
}

.card {
    .card-header {
        background-color: white;

        .card-title {
            font-size: 20px;
            text-align: left;
        }
    }
    .card-body {
        padding: 0;
        overflow-y: auto;

        .table-head {
            .col {
                display: flex;
                align-items: center;
                overflow: hidden;
                font-size: .9rem;
            }
        }
        .project-list {
            .accordion-head {
				.outer-row {
					& > .col {
						display: flex;
						position: relative;
						overflow: hidden;
						padding: .5rem 1rem;
	
						@include sm-d {
							flex-direction: row;
						}
	
						&-number {
							display: block;
							font-size: .8rem;

							.row {
								padding-bottom: 1rem;
								position: relative;

								@include sm-d {
									padding: .5rem 0;
								}
							}
	
							@include sm-d {
								font-size: 1rem;		
							}
						}						
	
						.progress {
							@include sm-d {
								order: 1;
							}
						}
						.status {
							font-size: 12px;
							white-space: nowrap;
							color: rgb(126, 126, 126);
							padding: 5px 0;

							@include md-d {
								font-size: 8px;
							}
							@include sm-d {
								font-size: 1rem;
							}
						}
					}

					.col {
						&:before {
							content: attr(data-label);
							font-size: .55rem;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
							width: 100%;
							margin-right: 10px;
							text-align: center;
							display: block;
							color: #7a7a7a;
	
							@include sm-d {
								font-size: 1rem;
								position: initial;
								left: auto;
								transform: none;
								order: 1;
								width: auto;
								max-width: 40%;
								text-align: left;

								&:before {
									content: ":";
									margin-right: 5px;
								}

							}

						}

						&.hide-label {
							&:before {
								content: none;
								display: none;
							}
						}
					}

					.text {
						@include sm-d {
							order: 2;
						}
					}
				}
            }
        }
    }
}