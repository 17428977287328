.App {
  height: 100%;
  text-align: center;
  position: relative;

  display: flex;
  flex-direction: column;
}

html,
body,
#root {
  height: 100%;
}