// Loader
.filter_loader_box {
    display: inline-block;
    margin: 1rem;
  
    &:first-of-type {
        margin-left: 0;
    }
    #loader-4 span {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #3498db;
        margin: 0 5px;
        opacity: 0;
    }
    #loader-4 span:nth-child(1) {
        animation: opacitychange 1.2s ease-in-out infinite;
    }
    #loader-4 span:nth-child(2) {
        animation: opacitychange 1.2s ease-in-out 0.33s infinite;
    }
    #loader-4 span:nth-child(3) {
        animation: opacitychange 1.2s ease-in-out 0.66s infinite;
    }
    @-moz-keyframes opacitychange {
        0%, 100% {
            opacity: 0;
        }
        70% {
            opacity: 1;
        }
    }
    @-webkit-keyframes opacitychange {
        0%, 100% {
            opacity: 0;
        }
        70% {
            opacity: 1;
        }
    }
    @-o-keyframes opacitychange {
        0%, 100% {
            opacity: 0;
        }
        70% {
            opacity: 1;
        }
    }
    @keyframes opacitychange {
        0%, 100% {
        opacity: 0;
        }
        70% {
        opacity: 1;
        }
    }
}