.detail-page {
	font-size: 1.1rem;
	padding: 2rem 0 6rem;
	width: 100%;
	overflow-x: hidden;

	.title {
		margin-bottom: 1.5rem;
	}

	.control-block {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: .5rem;
	}

	.manipulate-btn {
		font-size: 30px;
		cursor: pointer;

		svg {
			transition: .2s;
			
			&:hover {
				fill: #D8840A;
			}
		}
	}

	.content {
		.equipment_block {
			.row {
			}
		}
	}


	.row {
		margin-bottom: .5rem;

		.label {
			color: #6c6d6e;
			font-size: 1.2rem;
			font-weight: 500;
			//text-transform: uppercase;
			margin-bottom: .5rem;
			text-align: left;
		}

		.col {
			text-align: left;
		}
	}
}



// Таблица - общие стили
.detail-table {
	width: 100%;
	min-width: 1280px;
	table-layout: inherit;
	text-align: left;
	font-size: 1rem;

	tr {

		th,
		td {
			&:first-child {
				width: 100px;
			}
		}
	}

	thead {
		th {
			white-space: wrap;
			overflow: hidden;
			word-break: normal;
			word-wrap: break-word;
			overflow-wrap: normal;
			cursor: default;
		}
	}

	tbody {
		tr {
			td {
				white-space: wrap;
				overflow: hidden;
				word-break: normal;
				word-wrap: break-word;
				overflow-wrap: break-word;
			}
		}
	}
}

// Таблица - Склад
.warehouse-table {
	tr {

		th,
		td {
			&:first-child {
				width: 50px;
			}

			&.col-number {
				width: 50px;
			}

			&.col-daysLeft {
				width: 110px;
			}
		}
	}
}

// Таблица - Движение в цеху
.workshopMovement-table {
	tr {

		th,
		td {
			&:first-child {
				width: 50px;
			}

			&.col-name {
				width: 250px;
			}

			input[type="text"] {
				width: 140px;
			}
		}
	}
}

// Таблица - Снабжение
.supply-table {
	table-layout: inherit;

	tr {

		th,
		td {
			border: none;
			max-width: 180px;

			&:first-child {
				width: 50px;
			}

			&.col-count {
				width: 130px;

				// @include wide {
				//     width: 80px;
				// }
			}
		}
	}

	tbody {
		tr {

			// Добить
			&:nth-child(3n+2) {
				border-bottom: 2px solid rgb(233 233 233);
			}
		}
	}

	.control-buttons {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.svg-box {
			width: 40px;
			height: 40px;
			padding: 5px;

			&.btn-replace {
				padding: 5px 0;
				cursor: pointer;

				&:hover {
					svg {
						fill: #ff8562;
					}
				}
			}

			svg {
				width: 100%;
				height: 100%;
				fill: #b2bec9;
			}


			&.active {
				svg {
					fill: #ff8562;
				}
			}
		}

		.btn-replace {
			padding-left: 8px;
			padding-right: 8px;
			border: none;
			background: white;

			&.disabled {
				pointer-events: none;
			}
		}
	}
}